import { Flex, type FlexProps } from '@chakra-ui/react'
import React from 'react'

type Props = { children?: React.ReactNode }

const MagicBoxHeader: React.FC<Props & FlexProps> = ({
  children,
  background = 'gray.50',
  ...restProps
}) => {
  return (
    <Flex
      py="2"
      px="4"
      width="100%"
      justifyContent="space-between"
      borderBottom="1px solid"
      borderColor="gray.200"
      flexDir="column"
      background={background}
      {...restProps}
    >
      {children}
    </Flex>
  )
}

export default MagicBoxHeader
